import React, { useContext, useEffect } from "react";
import styles from "./style.module.scss";
import DutyPhonesGroup from "./DutyPhonesGroup";
import DutyPhoneModal from "./DutyPhoneModal";
import Spinner from "../Common/Spinner";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { exportDutyPhonesToCSV } from "@services/export";
import ExportButton from "../Common/ExportButton";
import Content from "../Common/Content";

const DutyPhonesPage = () => {
  const { notificationGroups: groups, dutyPhonesStore } =
    useContext(HomeContext);
  const {
    initDutyPhones,
    ready: isReady,
    dutyPhonesModalData: modalData,
  } = dutyPhonesStore;

  useEffect(() => {
    initDutyPhones();
  }, []);

  if (!isReady) {return <Spinner />;}

  return (
    <Content>
      <div className={styles.dutyPhonesPage}>
        <div className={styles.menu}>
          <ExportButton
            onClick={() => exportDutyPhonesToCSV(dutyPhonesStore.dutyPhones)}
          />
        </div>
        {groups.map((g) => (
          <DutyPhonesGroup key={g} group={g} />
        ))}
        {modalData && <DutyPhoneModal initialValue={modalData} />}
      </div>
    </Content>
  );
};

export default observer(DutyPhonesPage);
