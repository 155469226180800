import User from "../models/user";
import Role from "../models/role";
import { DutyPhone } from "@models/dutyPhone";
import { PtsSchedule } from "@models/pts";
import { keyBy } from "lodash";
import { getPtsLabel } from "./ptsUtils";
import { getConfig } from "@di";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

function exportFile(filename: string, text: string) {
  if (window.navigator.msSaveBlob) {
    console.log("Save like IE");
    const blobObject = new Blob([text]);
    window.navigator.msSaveBlob(blobObject, filename);
    return;
  }

  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename.replace(/\s/g, "_"));
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function exportUsersToCSV(users: User[], roles: Role[]) {
  const rolesMap = keyBy(roles, "id");

  let columns: [string, (u: User) => string][] = [
    ["Email", (u: User) => u.email],
    ["Full name", (u: User) => u.profile.full_name || ""],
    ["Company", (u: User) => u.profile.company || ""],
    ["Phone", (u: User) => u.phone?.phone || ""],
    ["Roles", (u: User) => u.roles.map((r) => rolesMap[r]?.name).join(",")],
  ];

  const { enableDutyPhones } = getConfig();
  if (!enableDutyPhones) {
    const [user, name, company, phone, roles] = columns;
    columns = [user, name, company, roles];
  }

  let text = columns.map((c) => c[0]).join(";") + "\n";
  users.forEach((u) => {
    text += columns.map((c) => c[1](u)).join(";") + "\n";
  });

  exportFile("users.csv", text);
}

export function exportDutyPhonesToCSV(phones: DutyPhone[]) {
  const columns: [string, (p: DutyPhone) => string][] = [
    ["Phone", (p: DutyPhone) => p.phone],
    ["Group", (p: DutyPhone) => p.group],
    ["Active", (p: DutyPhone) => (p.active ? "Yes" : "No")],
  ];

  let text = columns.map((c) => c[0]).join(";") + "\n";
  phones.forEach((p) => {
    text += columns.map((c) => c[1](p)).join(";") + "\n";
  });

  exportFile("phones.csv", text);
}

export function exportPtsScheduleToCSV(ptsSchedule: PtsSchedule[]) {
  const columns: [string, (p: PtsSchedule) => string | number][] = [
    ["Name", (p: PtsSchedule) => getPtsLabel(p.id)],
    ["Direction", (p: PtsSchedule) => p.direction || ""],
    ["Uninterruptible", (p: PtsSchedule) => (p.uninterruptible ? "Yes" : "No")],
    ["Start ideal time", (p: PtsSchedule) => p.start.idealTime],
    ["End ideal time", (p: PtsSchedule) => p.end.idealTime],
    ["Orange start", (p: PtsSchedule) => p.start.orangeInterval.start ?? ""],
    ["Orange end", (p: PtsSchedule) => p.start.orangeInterval.end ?? ""],
    ["Red start", (p: PtsSchedule) => p.start.redInterval.start ?? ""],
    ["Red end", (p: PtsSchedule) => p.start.redInterval.end ?? ""],
  ];

  let text = columns.map((c) => c[0]).join(";") + "\n";
  ptsSchedule.forEach((p) => {
    text += columns.map((c) => c[1](p)).join(";") + "\n";
  });

  exportFile("pts-schedule.csv", text);
}
